<template>
  <b-overlay :show="isLoading" rounded="lg">
    <b-card title="Edit Delivery Order Status" sub-title="Manually Change DO Status">
      <b-col cols="12">
        <!--this is options for search data, only "Filter by Status" will dissapear on mobile view-->
        <br>
        <b-row class="hide-on-mobile">
          <b-col md="6"> 
            <label for="search" class="form-label">Please input DO number that want to edit</label>
            <b-form-input
              v-model="search"
              type="search"
              placeholder="Input DO Number"
              style="float: right;"
            />
          </b-col>
          <b-col cols="6" v-if="this.search !=''">
            <b-button style="margin-top: 22px;" @click="doFiltering" variant="primary">Submit</b-button>
          </b-col>
        </b-row>
        <b-row class="hide-on-desktop">
          <b-col cols="6"> 
            <label for="search" class="form-label">Please input DO number that want to edit</label>
            <b-form-input
              v-model="search"
              type="search"
              placeholder="DO"
              style="float: right;"
            />
          </b-col>
          <b-col cols="6" v-if="this.search !=''">
            <b-button style="margin-top: 22px;" @click="doFiltering" variant="primary">Submit</b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="canEdit">
          <b-col cols="6">
            <label for="selectStatus" class="form-label">Please Select Status</label>
            <b-form-select
              v-model="selectStatus"
              :options="status"
              placeholder="Select"
              @change="handleSelectChange"
            >
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="buttonSubmit">
          <b-col cols="6">
            <b-button style="margin-top: 22px;" @click="editDo" variant="success">Edit DO Status</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import { userAccess, dateFormat, sleep } from "@/utils/utils";

export default {
  data() {
    return {
      search : "",
      isLoading : false,
      canEdit : false,
      selectStatus : "",
      status : [],
      buttonSubmit : false,
      doData : {},
    }
  },
  computed: {
    permission() {
      let result = userAccess("Edit DO Status", "utilitytools_menu");
      // console.log("Delivery Orders Sumitomo",result)
      return result;
    },
  },
  created() {
    document.title = "Edit DO Status | RSP";
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    ...mapActions({
      getDeliveryOrder: "delivery/getDeliveryOrder",
      editDOStatus: "delivery/editDOStatus",
      getAllInventories: 'inventory/getAllInventories'
    }),
    queryDelivery() {
      this.getDeliveryOrder({
        sapDo: this.search,
      }).then((data) => {
        this.doData =  data.data[0]
        this.isLoading = false
        console.log(data.data[0].status);
        if(data.data[0].status !== "Delivery in Progress" 
        && data.data[0].status !== "Scanning Completed" 
        && data.data[0].status !== "Fulfilled"
        && data.data[0].status !== "Collected" ){
          this.canEdit = false
          this.$bvToast.toast("This Delivery Order cannot be Edited", {
          title: "Warning",
          variant: "danger",
          solid: true,
          });
        }else {
          if(data.data[0].status === "Fulfilled" || data.data[0].status === "Collected" ){
            this.getAllInventories({ 
              sapDo: data.data[0].sapDo,
            }).then((datas) => {
              let items =  datas.data
              // Check if any item has warrantyStatus other than "Pre"
              const hasNonPreWarranty = items.some((item) => item.warrantyStatus !== "Pre");
          
              if (hasNonPreWarranty) {
                this.$bvToast.toast("One of the item inside this DO has been registered for warranty. Unable to change DO status", {
                title: "Error",
                variant: "danger",
                solid: true,
                });
              }else{
                this.canEdit = true
                this.$bvToast.toast("This Delivery Order can be edited", {
                title: "Success",
                variant: "success",
                solid: true,
                });
      
                if(data.data[0].status == "Delivery in Progress"){
                  this.status = ["Scanning in Progress", "Scanning Completed"]
                }else if(data.data[0].status == "Fulfilled" || data.data[0].status == "Collected"){
                  this.status = ["Delivery in Progress"]
                }else {
                  this.status = ["Scanning in Progress"]
                }
              }
            })
          }else{
            this.canEdit = true
            this.$bvToast.toast("This Delivery Order can be edited", {
            title: "Success",
            variant: "success",
            solid: true,
            });
  
            if(data.data[0].status == "Delivery in Progress"){
              this.status = ["Scanning in Progress", "Scanning Completed"]
            }else if(data.data[0].status == "Fulfilled"){
              this.status = ["Delivery in Progress"]
            }else {
              this.status = ["Scanning in Progress"]
            }
          }
        }
      }).catch((e) => {
        this.canEdit = false
        this.$bvToast.toast("Delivery Order is not found", {
        title: "Warning",
        variant: "danger",
        solid: true,
        });
        console.log(e);
      });
    },
    doFiltering() {
      if (this.search === "") {
        this.$bvToast.toast("Please Input DO Number", {
        title: "Warning",
        variant: "danger",
        solid: true,
        });

        return;
      }
      this.queryDelivery();
    },
    handleSelectChange(){
      this.buttonSubmit = true
    },
    editDo(){
      this.isLoading = true;
      
      this.editDOStatus({ idDelivery: this.doData._id, deliveryData: {
        status: this.selectStatus,
      }})
        .then(async(res) => {
          await axios
            .post("/sales-and-purchase/drcc/transaction-log-record", {
              sapDo: this.doData.sapDo,
              statusBefore: this.doData.status,
              statusAfter: this.selectStatus
            })
            .then(async(response) => {
              this.isLoading = false;
              this.search = ""
              this.buttonSubmit = false
              this.canEdit = false

              this.$bvToast.toast("Successfully Add Transaction Log Record", {
                title: "Success",
                variant: "success",
                solid: true,
              });
              await sleep(1000)
            })
            .catch((error) => {
              this.isLoading = false;
              const errorMessage = error.response.data.errors[0].message
              console.log(errorMessage);
              this.$bvToast.toast(errorMessage, {
                title: "Fail",
                variant: "danger",
                solid: true,
              });
            });

          var sapCall = !res.apiResult
            ? { type: "N" }
            : !res.apiResult.apiResult
            ? { type: "N" }
            : res.apiResult.apiResult;
          if (sapCall.type == "E") {
            this.$bvToast.toast(
              "Fail to send notification to SAP. Please inform administrator",
              {
                title: "Failed",
                variant: "danger",
                solid: true,
              }
            );
          }
        })
        .catch((e) => {
          this.isLoading = false;
          console.log(e);
          this.$bvToast.toast(e.response.data.message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
        })
        .finally(() => {
          this.isLoading = false;
        });
      }
  },
};
</script>
<style scope>
.hide-at-all {
  display: none !important;
}

/* For mobile phones: */
@media (max-width: 761px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* For computer: */
@media (min-width: 761px) {
  .hide-on-desktop {
    display: none !important;
  }
  .show-on-desktop {
    display: inline !important;
  }
}
</style>
